import { axiosApiInstance } from "@/plugins/axios";
function get_total_input_sales() {
  this.loading = true;

  axiosApiInstance({
    url: this.url + "total",
    method: "get",
    params: {
      dates: this.criteria.selectedPeriod,
    },
  })
    .then((res) => {
      this.loading = false;
      this.total = res.data.data > 0 ? res.data.data : 0;
    })
    .catch((error) => {
      this.loading = false;
      this.$vToastify.error("Failed to load total input sales");
      this.$vToastify.error(error.response.data.message);
    });
}

export { get_total_input_sales };
