import moment from "moment";
import { bus } from "@/main.js";
import { axiosApiInstance,axiosApiInst } from "@/plugins/axios";
const SALES_URL = (project_id)=>process.env.VUE_APP_BACKEND + "/api/v3/"+project_id+"/input-sales";
/**
 * 
 * @typedef Sale 
 * @property {string} name
 * @property {string} phone
 * @property {string} advance_type
 * @property {string} amount
 * @property {number} price
 * @property {string} unit
 * @property {string} unit_type
 * @property {string} user_type
 */

function format_date(date) {
  return date ? moment(date).format("ll") : "-";
}

function reload() {
  this.get_input_sales();

  this.inputskey++;
}
function get_input_sales() {
  this.loading = true;

  axiosApiInstance({
    url: this.url,
    method: "get",
    params: {
      dates:this.criteria.selectedPeriod
    }
  })
    .then((res) => {
      this.loading = false;
      this.items = res.data.data;
    })
    .catch((error) => {
      this.loading = false;
      this.$vToastify.error('Failed to load input sales');
      this.$vToastify.error(error.response.data.message);
    });
}

function view_user(item) {
  bus.$emit("farmer-view", {
    name: item.name,
    data: item,
    farmer_id: item.user_id,
    tab: "farm_inputs",
    subtab:"input_sales",
  });
}
/**
 * 
 * @param {number} project_id 
 * @param {Sale[]} records 
 */
async function upload_sales(project_id,records){
  const res = await axiosApiInst({
    url:SALES_URL(project_id)+"/upload-sales",
    method:'post',
    data:records
  });
  return res
}
export {
  format_date,
  reload,
  get_input_sales,
  view_user,
  upload_sales
};
