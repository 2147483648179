<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">INPUT SALES</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button
          squared
          variant="outline-success mr-2"
          :disabled="!can('modify', 'inputs')"
          to="/settings/farm_input"
        >
          <b-icon-gear></b-icon-gear> Manage Inputs
        </b-button>
        <b-button
          v-b-modal.add-input-sale
          squared
          variant="primary"
          :disabled="!can('modify', 'inputs')"
        >
          <b-icon-plus></b-icon-plus> New Record
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <div class="row m-0">
        <b-col class="">
          <div class="d-flex">
            <b-select
              v-model="criteria.supplier"
              placeholder="Select Supplier"
              :options="suppliers"
              text="All suppliers"
              class="mr-2 form-select"
            >
            </b-select>
            <b-form class="-ml-auto">
              <b-form-input
                placeholder="Search Name"
                type="search"
                class="search"
                v-model="criteria.searchfield"
              ></b-form-input>
            </b-form>
            <div class="pl-2">
              <date-picker @dates-selected="cfilterByDate"></date-picker>
            </div>
          </div>
        </b-col>
        <div class="col-auto">
          <div class="text-right">
            <b-button class="btn-opt primary" v-b-modal.upload-sales>
              <b-icon-upload></b-icon-upload> Import Input Sales
            </b-button>
            <button class="btn-opt primary" @click="reload">
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button class="btn-opt primary" v-print="'#inputSalesTable'">
              <b-icon-printer></b-icon-printer> Print
            </button>
            <download-excel
              class="btn btn-opt primary"
              :data="items"
              type="csv"
            >
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </div>
      </div>
    </div>

    <b-row class="m-0">
      <b-col class="pt-2">
        <b-table
          v-show="items.length > 0 && !loading"
          id="inputSalesTable"
          :fields="fields"
          :items="items"
          :filter="criteria"
          sticky-header="75vh"
          @row-clicked="view_user"
          :filter-function="filterItems"
          hover
          thead-class="bg-primary-tb text-primary-tb border-primary-tb"
        >
          <template #cell(name)="data">
            <b-avatar icon="person">{{ data.item.name[0] }}</b-avatar>
            {{ data.item.name }}
          </template>
          <template #cell(usertype)="data">
            <usertype-pill :user_type="data.item.user_type"> </usertype-pill>
          </template>
          <template #cell(amount)="data">
            {{ data.item.amount.toLocaleString() }}
          </template>
        </b-table>
        <b-container v-show="loading">
          <b-row class="d-flex justify-content-center p-3">
            <b-spinner
              style="width: 3rem height: 3rem"
              label="Large Spinner"
            ></b-spinner>
          </b-row>
        </b-container>
        <b-container class="text-center" v-if="items.length === 0 && !loading">
          <p class="text-muted pt-3">
            You have no Input Sales. Click to record your first Input Sale.
          </p>
          <b-button variant="outline-primary" v-b-modal.add-input-sale>
            New Record
          </b-button>
        </b-container>
      </b-col>
      <b-col cols="auto" class="pt-2">
        <total-sales
          :criteria="criteria"
          :key="'total' + criteria.selectedPeriod+inputskey"
        ></total-sales>
        <input-distribution
          :criteria="criteria"
          :key="'distribution' + inputskey + criteria.selectedPeriod"
        ></input-distribution>
      </b-col>
    </b-row>
    <add-input-sale
      modal_id="add-input-sale"
      @AdditionSuccess="reload"
    ></add-input-sale>
    <farmer-modal @Success="reload"></farmer-modal>
    <upload-sales></upload-sales>
  </div>
</template>
<script>
import { can } from "@/modules/auth/utils.js";
import UsertypePill from "@/components/usertype_pill/UsertypePill.vue";
import InputDistribution from "./components/input_distribution/InputDistribution.vue";
import TotalSales from "./components/total_sales/TotalSales.vue";
import AddInputSale from "@/components/add_input_sale/AddInputSale.vue";
import DatePicker from "@/modules/produce/components/ProduceDatePicker.vue";
import dateMixin from "@/modules/produce/date_mixin";
import FarmerModal from "@/components/farmer_modal/Farmer.vue";
import moment from "moment";
import {
  format_date,
  reload,
  get_input_sales,
  view_user,
} from "./actions.js";
import UploadSales from './components/UploadSales.vue';
export default {
  components: {
    InputDistribution,
    AddInputSale,
    FarmerModal,
    UsertypePill,
    DatePicker,
    TotalSales,
    UploadSales
  },
  mixins: [dateMixin],
  data() {
    let fields = [
      "name",
      { key: "usertype", label: "" },
      "contact",
      {
        key: "amount",
        label: "Input Amount",
        tdClass: "text-right fw-bold",
        thClass: "text-right",
      },
      {
        key: "date",
        label: "Date",
        tdClass: "text-muted",
        formatter: (value) => {
          return this.format_date(value);
        },
      },
    ];
    let searchfield = null;
    let suppliers = [
      { value: null, text: "All Suppliers" },
      { value: "farmer", text: "Farmers" },
      { value: "va", text: "Agents" },
      { value: "farmer_grup", text: "Farmer Groups" },
    ];
    let supplier = null;
    return {
      fields,
      searchfield,
      suppliers,
      items: [],
      supplier,
      loading: false,
      inputskey: 0,
      criteria: {
        supplier: null,
        searchfield: null,
        selectedPeriod: [""],
      },
    };
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/";
    },
    url() {
      return this.baseUrl + this.project_id + "/input-sales/";
    },
  },

  methods: {
    can,
    format_date,
    reload,
    get_input_sales,
    view_user,
    filterItems(row, criteria) {
      let filters = [];
      if (criteria.supplier) {
        filters.push(row.user_type == criteria.supplier);
      }
      if (criteria["selectedPeriod"].length == 2) {
        filters.push(
          moment(row["date"]).isBetween(...this.criteria.selectedPeriod)
        );
      }
      if (criteria.searchfield) {
        filters.push(
          row["name"].toLowerCase().includes(criteria.searchfield.toLowerCase())
        );
      }
      return !filters.includes(false);
    },
  },
  mounted() {
    this.dateInit();
    this.reload();
  },
};
</script>
