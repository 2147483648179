import { axiosApiInst } from "@/plugins/axios";
function getInputSalesBreakdown() {
  axiosApiInst({
    url:this.url,
    method:'get',
    params:{
      dates:this.criteria.selectedPeriod,
    }
  })
    .then((response)=>{
      this.input_sales_stats = response.data.data;
      this.getChartData();
      // this.loadingAdvanceBalance = false;
    })
    .catch((error)=>{
      this.$vToastify.error("Loading Failed");
      this.$vToastify.error(error.response.data.message);
    });
}
function getChartData() {

  for (const x of this.input_sales_stats) {
    this.categories.push(x.category);
    this.chartData.push(x.amount);
  }
  this.series = [
    {
      data: this.chartData,
    },
  ];

  this.chartOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: this.categories,
    },
  };
}

export {  getChartData, getInputSalesBreakdown };
