import Vue from "vue";
import moment from "moment";
import { Periods } from "./action";
const dateMixin = Vue.mixin({
    methods: {
        dateInit() {
            const cb = this.$store.getters["dates/dateRange"];
            const [startDate, endDate] = cb();
            if (startDate) {
                this.criteria.selectedPeriod = [startDate, endDate];
                this.selectedPeriod = "Custom Range";
            }
        },
        filterByDate() {
            if (this.selectedPeriod == "Custom Range") return 0;
            this.criteria.selectedPeriod = Periods[this.selectedPeriod];
        },
        cfilterByDate([startDate, endDate]) {
            const start = moment(startDate).subtract(1,'day').endOf('D');
            const end = moment(endDate).endOf('day');
            this.criteria.selectedPeriod = [start, end];
        }
    }
});
export default dateMixin;