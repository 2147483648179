<template>
  <b-modal id="upload-sales" size="md" title="Upload Sales" hide-footer>
    <b-row>
      <b-container>
        <h4 class="dark font-weight-bold">STEP 1</h4>
        <h5 class="dark font-weight-light">Download CSV from template</h5>
        <download-excel
          :data="json_data"
          class="btn btn-primary"
          :fields="json_fields"
          type="csv"
          :escapeCsv="false"
        >
          <b-icon icon="download" aria-hidden="true"></b-icon>
          Download template
        </download-excel>
        <hr />
        <h4 class="dark font-weight-bold">STEP 2</h4>
        <h5 class="dark font-weight-light">
          Open the template with Excel, Fill the form columns with farmers
          details and save the file
        </h5>
        <hr />
        <h4 class="dark font-weight-bold">STEP 3</h4>
        <h5 class="dark font-weight-light">Upload the .csv file</h5>
        <b-form-file @change="pickfile" v-model="fyl" ></b-form-file>
        <b-button
          @click="sendfile"
          variant="primary"
          class="w-100 mt-2"
          :disabled="imported_data.length < 1"
        >
          <b-spinner small label="Small Spinner" v-show="uploading"></b-spinner>
          Upload File</b-button
        >
      </b-container>
    </b-row>
  </b-modal>
</template>
<script>
import * as csvParser from "papaparse";
const Joi = require("joi");
const schema = Joi.object({
  name: Joi.string().min(3).required(),
  phone: Joi.number().required(),
  district: Joi.string().required(),
  advance_type: Joi.string().required(),
  adv_name: Joi.string().required(),
  amount: Joi.number().required(),
  price: Joi.number().required(),
  quantity: Joi.number().required(),
  unit: Joi.string().required(),
  unit_index: Joi.string().required(),
  user_type:Joi.string().required(),
  village:Joi.string().required()
});
import { upload_sales } from "../actions";
export default {
  data() {
    return {
      json_fields: {
        name: "name",
        phone: "phone",
        district: "district",
        //order
        advance_type: "advance_type",
        adv_name: "adv_name",
        amount: "amount",
        price: "price",
        quantity: "quantity",
        unit: "unit",
        unit_index: "unit_index",
        user_type: "user_type",
        village: "village",
      },
      json_data: [
        {
          name: "",
          phone: "",
          advance_type: "",
          adv_name: "",
          amount: "",
          price: "",
          quantity: "",
          unit: "",
          unit_index: "",
          user_type: "",
          village: "",
        },
      ],
      /**@type import("../actions").Sale[] */
      imported_data: [],
      uploading: false,
      fyl:null,
    };
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
  },
  methods: {
    pickfile(e) {
      const fyl = e.target.files[0];
      fyl.text().then((fstr) => {
        const { data } = csvParser.parse(fstr.trim(), { header: true });
        const row_index = (idk)=>idk+1;
        try{
            this.imported_data = data.map((el, idx) => {
            const result = schema.validate(el);
            if(result.error){
              throw new Error(result.error.details[0].message +" at row "+ row_index(idx));
            }
            return result.value;   
          });
        }catch (err) {
            console.error(err);
            this.imported_data = [];
            this.fyl = null;
            this.$bvToast.toast(err.message, {
              title: "Csv Error",
              variant:"danger",
            });
          }
      });
    },
    sendfile() {
      this.uploading = true;
      upload_sales(this.project_id, this.imported_data).then(() => {
        this.uploading = false;
      });
    },
  },
};
</script>