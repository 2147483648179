<template>
  <div>
    <date-range-picker v-model="dateRange" autoApply @select="onDateRangeSelect">
      <template v-slot:input="picker">
        <small><b>{{ picker.startDate | fdate }} - {{ picker.endDate | fdate }}</b></small>
      </template>

      <template #ranges="ranges">
        <date-ranges :ranges="ranges"></date-ranges>
      </template>
      <div slot="footer" slot-scope="data" class="container-fluid border-top">
        <div class="calendar-footer pt-2 pb-2">
          <b-button
            class="border"
            size="sm"
            variant="outline-danger"
            @click="reset"
          >
            Reset
          </b-button>
          <b-button
            class="border"
            size="sm"
            variant="secondary"
            @click="data.clickCancel"
          >
            cancel
          </b-button>
          <div>
            <b class="text-black">Selected Dates</b> {{ data.rangeText }}
          </div>

          <b-button
            class="float-right"
            size="sm"
            variant="success"
            @click="data.clickApply"
            v-if="!data.in_selection"
            >Apply</b-button
          >
        </div>
      </div>
    </date-range-picker>
  </div>
</template>
<script>
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRanges from "@/components/date_ranges/DateRanges.vue";
// import { EmbedPlugin } from 'bootstrap-vue';
export default {
  components: { DateRangePicker, DateRanges },
  filters: {
    fdate(val) {
      return val ? moment(val).format("LL") : "";
    },
  },
  data() {
    return {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      nonSelect:{
        startDate: null,
        endDate: null,
      },
      allTime:{
        startDate:moment("2020-01-01").toDate(), 
        endDate:moment().toDate()
      },
    };
  },
  mounted() {
    const cb = this.$store.getters["dates/dateRange"];
    const [startDate, endDate] = cb();
    this.dateRange = { startDate, endDate };
  },
  methods: {
    fmtdate(datestr) {
      if (!datestr) return null;
      return moment(datestr).format("YYYY-MM-DD");
    },
    onDateRangeSelect({ startDate, endDate }) {
      this.$emit("dates-selected", [
        this.fmtdate(startDate),
        this.fmtdate(endDate),
      ]);
    },
    reset(){
      this.dateRange = this.nonSelect;
      this.onDateRangeSelect(this.allTime);
    }
  },
};
</script>
<style lang="css" scoped>
.btn-outline-danger:hover {
  background: #721c24 !important;
}
.btn-outline-danger {
  color: #721c24 !important;
  border-color: #721c24 !important;
}
.calendar-footer {
  display: flex;
  justify-content: space-between;
  width:100%;
}
</style>
