<template>
  <div>
    <div>
      <h6 class="subtitle ">INPUT DISTRIBUTION</h6>
    </div>
    <bar-graph :series="series" :chartOptions="chartOptions"></bar-graph>
    <b-link href="#" v-show="false">View Full Report</b-link>
  </div>
</template>

<script>
import BarGraph from "@/modules/farm_input/components/BarGraph.vue";
import { getInputSalesBreakdown, getChartData } from "./actions";
export default {
  components: { BarGraph },
  props: {
    criteria:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data() {
    return {
      series: [],
      categories: [],
      chartData: [],
      input_sales_stats: [],
      chartOptions: {},
    };
  },

  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/";
    },
    url() {
      return this.baseUrl + this.project_id + "/input-sales/stats";
    },
  },

  methods: {
    getInputSalesBreakdown,
    getChartData,
  },

  mounted() {
    this.getInputSalesBreakdown();
  },
};
</script>

<style></style>
