/**
 * 
 * @param {URLSearchParams} query 
 * @param {number} project_id 
 */
function set_project(query,project_id){
    query.set('project_id',project_id)
    return query;
}
/**
 * 
 * @param {URLSearchParams} query 
 * @param {Array<string>} seasons 
 */
function set_season(query,seasons){
    if(seasons.length<0){
        seasons.map(el=>query.append('season',el));
    }
    return query;
}
export {set_project,set_season}