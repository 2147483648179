<template>
  <b-alert variant="info" show class="alert-totals">
    <h6 class="title">Total Input Sales</h6>
    <div class="text-center" v-if="loading">
      <b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>
    <h3 class="m-0" v-if="!loading">
      UGX {{ total.toLocaleString() }}
    </h3>
  </b-alert>
</template>
<script>
import { get_total_input_sales } from "./actions";
export default {
  props: {
    criteria: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
    };
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/";
    },
    url() {
      return this.baseUrl + this.project_id + "/input-sales/";
    },
  },
  methods: {
    get_total_input_sales,
  },
  mounted() {
    this.get_total_input_sales();
  },
};
</script>
