import moment from "moment";
import { bus } from '@/main.js';
import {axiosApiInstance} from "@/plugins/axios";
import * as QParams from './qparams'
// eslint-disable-next-line no-unused-vars
import Vue from "vue";
function onFarmerClick(item){
    item['parentComp'] = "produce";
    bus.$emit("farmer-view", {
      name: item.name,
      data: item,
      farmer_id: item.farmer_id,
      tab: "produce",
    });

}

function onBulkSuccess(){
    this.fetchData(this);
    this.$bvModal.hide('farmer-view');
}


function formatDate(date){
    return moment(date).format('YYYY-MM-DD')
  }
function routePage(){
    this.$router.push({path:"/settings/produce"});
}
/**
 * 
 * @typedef Produce
 * @property {string} agent_name
 * @property {string} agent_phone
 * @property {number} amount
 * @property {string} date
 * @property {string} district
 * @property {string} farmer_dob
 * @property {string} farmer_gender
 * @property {string} farmer_id
 * @property {string} farmer_phone_number
 * @property {number} foreign_matter
 * @property {number} gross_weight
 * @property {string} id
 * @property {number} moisture_content
 * @property {string} name
 * @property {string} notes
 * @property {string} payment_method
 * @property {string} produce
 * @property {number} quantity
 * @property {string} receipt_number
 * @property {string} submitted_by
 * @property {string} submitter_phone
 * @property {string} supplier_type
 * @property {number} unit_price
 *  
 */
/**
 * 
 * @param {{items:Produce[]}} dataObj 
 */
async function fetchData(dataObj){
    dataObj.loading = true;
    const qparams = new URLSearchParams;
    
    QParams.set_season(qparams,dataObj.season);
    QParams.set_project(qparams,dataObj.projectId);
    const result = await axiosApiInstance.get(
      dataObj.url+'/supplied-by-farmers',
      {params:qparams}
    );
    dataObj.totalPendingAmount = 0;
    dataObj.value_chains = result.data.crops;
    dataObj.items = result.data.data;
    dataObj.rows = result.data.data.length;
    // get pending payments
    const pending_payments = dataObj.items.filter(el=>el['payment_status']=='pending payment')
    const total_pending = pending_payments.reduce((a, b) => a + b['amount'], 0)
    dataObj.totalPendingAmount = total_pending;
    dataObj.loading = false;
  }
async function fetchStats(dataObj){
    dataObj.loading = true;
    const qparams = new URLSearchParams;
    
    QParams.set_project(qparams,dataObj.projectId);
    const result = await axiosApiInstance.get(
      this.url+'/timeline',
      {params:qparams}
    );
    dataObj.produces = result.data.data;
    dataObj.loading = false;
  }

/**
 * 
 * @param {{farmer_id:string}} item
 * @param {Vue} ui 
 */
function onAgentClick(item,ui){
  const vaId = item.farmer_id.replace(/\//g, "-");
  ui.$router.push(`/suppliers/agent-profile/${vaId}`);
}
/**
 * 
 * @param {{supplier_type:string}} item
 * @param {Vue} ui 
 */
function onRowClick(item,ui){
  if (item.supplier_type == "Farmer"){
    onFarmerClick(item);
  }
  if(item.supplier_type=="Agent"){
    onAgentClick(item,ui);
  }
}
/**
 * @param {Array<{date:string}>} data
 */
function formatDates(data){
  const items = data.map(el=>{
    const date_recv = el.date;
    const fdate = moment(date_recv).format(moment.HTML5_FMT.DATE);
    return {...el,date:fdate}
  });
  return items;
}
const dateFormat="YYYY-MM-DD";
const Periods = {
  "":['2020-01-01',moment().add(10,'y').format(dateFormat)],
  "Last Week":[moment().subtract(7,'d').format(dateFormat),moment().format(dateFormat)],
  "This Month":[moment().startOf('month').format(dateFormat),moment().format(dateFormat)],
  "Last Month":[moment().subtract(30,'d').format(dateFormat),moment().format(dateFormat)],
  "Last Quater":[moment().subtract(90,'d').format(dateFormat),moment().format(dateFormat)],
  "This Year":[moment().startOf('year').format(dateFormat),moment().format(dateFormat)]
};
export { 
  Periods,
    onBulkSuccess,onFarmerClick,
    formatDate,
    fetchData, routePage, fetchStats,
    onRowClick,
    formatDates
 }
