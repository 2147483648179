<template>
  <b-badge pill :variant="get_user_class(user_type)"
    >{{ get_user_label(user_type) }}
  </b-badge>
</template>
<script>
import {
    get_user_class,
    get_user_label,
} from "./actions"
export default {
  props: {
    user_type: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  methods: {
    get_user_class,
    get_user_label,
  },
  mounted(){

  }
};
</script>
